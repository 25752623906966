.go-to {
    font-size: 14px;
    line-height: 20px;
    color: white;
    float: right;
    font-weight: bold;
}

.go-to:hover {
    color: white;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0 !important;
    color: var(--main-color);
}

#scroll-top-img {
    position: fixed;
    font-size: 50px;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    color: var(--azure);
    transition: .3s opacity;
}

#scroll-top-img.show {
    opacity: .7;
}

.spinner-wrapper{
  position: fixed !important;
  width: 100%;
  height: 100%;
}
