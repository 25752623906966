body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }
body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5; }
body::-webkit-scrollbar-thumb {
  background-color: #46BBEB; }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --disabled: #F5F5F5;
    --giallo: #FFBB41;
    --rosso: #f15e5e;
    --lilla: #CBCBFF;
    --rosa: #E0BFE2;
    --viola: #8655A2;
    --muted-azure: #005A8E0D;
    --azure: #46BBEB;
    --ciano: #00A9DC;
    --main-color: #005A8E;
    --mid-blue: #004D79;
    --dark-blue: #002855;
    --verde-acqua: #42B0C5;
    --verde: #84BB78;
    --verde-chiaro: #ADBF20;
    --grigio-chiaro: #D2D2D2;
    --banner-input: #97A9C9;
    --main-text: #808285;
    --secondary-text: #939598;
    --titles: #414042;
}

.fontawesome-gellery-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    overflow: hidden auto;
    outline: 0;
    background-color: rgba(0, 0, 0, .5);
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    flex-wrap: wrap;
}

.fontawesome-gellery-modal .button-container {
    position: absolute;
    width: 800px;
    height: 60px;
    background: white;
    bottom: 25px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: none;
}

.fontawesome-gellery-modal .button-container svg {
    width: 20px;
    margin-right: 10px;
    margin-top: -4px;
}

.fontawesome-gellery-modal .button-container .btn:hover path {
    fill: var(--primary) !important;
}

.fontawesome-gellery-modal .button-container.show {
    display: inherit;
}

.fontawesome-gellery-modal .modal-content {
    overflow-y: scroll;
    height: 93vh;
    width: calc(100% - -18px);
    border-radius: 5px;
    position: static;
    padding-bottom: 50px !important;
}

.fontawesome-gellery-modal .btn.disabled {
    /*pointer-events: none;*/
    cursor: not-allowed;
}

.hide-scroll {
    height: 100vh;
    overflow: hidden;
}
